.login-continer {
  height: 100vh !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.hour-charts [val="1"] {
  fill: #f0e2f4 !important;
}
.hour-charts [val="1"]:hover {
  fill: #f0e2f4 !important;
  opacity: 0.8 !important;
  filter: none !important;
}
.highlight-label {
  fill: blue;
}

.highlight-label:hover {
  cursor: pointer !important;
}
table td {
  font-size: 0.8rem !important;
  position: relative;
}
th {
  position: sticky !important;
  background-color: #fafafa !important;
  font-size: 1rem !important;
}

th {
  white-space: nowrap !important;
  top: 0;
  font-weight: 600 !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.order-table > div {
  margin-top: 0 !important;
}
.h-100vh {
  height: 100vh !important;
}

.bg-white {
  background-color: white !important;
}
.nowrap {
  white-space: nowrap;
}

.drawer-s {
  top: 20px !important;
  left: 0px;
  z-index: 1;
}

.sketch-picker {
  box-shadow: none !important;
}

.popup-popover .MuiPopover-paper {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

svg.secondary {
  fill: #00acc1 !important;
  color: #00acc1 !important;
}
svg.disable {
  opacity: 0.2;
}

.alert-group .custom-select-box {
  margin: 0px !important;
}
.group-select > div {
  padding-bottom: 0px !important;
}

.group-type > div:nth-child(1) {
  margin-top: 19px !important;
}

.group-type-label {
  font-size: 0.7rem;
}

.text-primary {
  color: #8e24aa !important;
}

.w-600 {
  font-weight: 600 !important;
}

.label {
  color: #aaaaaa !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.42857;
}

.fill-white {
  color: white !important;
}

.active-link li div {
  color: #00acc1;
}

.nav-active {
  background-color: rgb(253 253 253 / 20%) !important;
  border-radius: 4px !important;
}

.-mx-3 {
  margin: -4rem !important;
}

.tabs .Mui-selected {
  color: #8e24aa !important;
}
.tabs .MuiTabs-indicator {
  background-color: #8e24aa !important;
}

.user-list-table tbody td:first-child {
  width: 100%;
}
/*  */

.tab-pannel {
  margin-left: -24px;
  margin-right: -24px;
}

.dashbord-table thead tr th {
  background-color: white !important;
  padding: 5px 10px;
  border: 1px solid #9999994d;
}

.dashbord-table tbody tr td {
  padding: 5px 10px;
  font-weight: 600;

  border: 1px solid #9999994d;
  cursor: pointer;
}

.dashbord-table tbody tr td div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltop:after {
  position: absolute;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  content: "";
  display: block;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.tooltop {
  position: absolute;
  background-color: black;
  border-radius: 8px;
  padding: 2px 10px;
  z-index: 99;
  display: none !important;
  width: 160px;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 12px;
}
.tooltip-target:hover .tooltop {
  display: block !important;
}

.dashbord-table {
  width: 100%;
  overflow: auto;
}

.fs14 {
  font-size: 14px;
}

.fit-content {
  width: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}
.input-label-24 {
  font-size: 12px;
  color: #aaaaaa;
}
